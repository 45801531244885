import React from 'react';
import PropTypes from 'prop-types';

const NCCustomersCard = ({ data }) => {
  return (
    <div className="customer-list">
      <h4>
        <strong>New & Critical Customers</strong>
      </h4>
      {data.map((item, index) => (
        <div key={index} style={{ marginBottom: '10px' }}>
          <span
            style={{
              fontWeight: 'bold',
              display: 'inline-block',
              marginRight: '5px'
            }}
          >
            {item.dayOfWeek} –
          </span>
          <span style={{ color: 'red' }}>{item.customerNames.join(', ')}</span>
        </div>
      ))}
    </div>
  );
};
NCCustomersCard.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.number])
};

const FaultSourcesCard = ({ faultSources, data, title }) => {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th scope="col">Department</th>
          <th scope="col">{title}</th>
        </tr>
      </thead>
      <tbody>
        {faultSources.map((source, index) => {
          const existeds = data.filter(item => item.faultSource === source.id);
          return (
            <tr key={index}>
              <td>{source.name}</td>
              <td>{existeds ? existeds.length : 0}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
FaultSourcesCard.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  faultSources: PropTypes.array,
  title: PropTypes.string
};
const CriticalReturnCcfCard = ({ data, url }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap', 
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        gap: '2px',
        padding: '10px',
      }}
    >
      {data.map((item, index) => (
        <span key={index}>
          <a
            href={url + `${item.id}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: 'blue',
              textDecoration: 'underline',
              margin: '0 5px',
            }}
          >
            {item.id}
          </a>
          {index < data.length - 1 && ', '}
        </span>
      ))}
    </div>
  );
};

CriticalReturnCcfCard.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  url: PropTypes.string
};


export { NCCustomersCard, FaultSourcesCard, CriticalReturnCcfCard };
