import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { searchComments } from 'services/customer-service/scActionPlanComment';
import IconButton from 'components/common/IconButton';
import {
  exportMsWork,
  sentMailWithAttactment
} from 'services/meeting-management-services/cusFocusExportService';
import { swalConfirm } from 'components/utilities/SwalToastUtil';
import {
  getSupplierDeliveryChangesAgedODRs,
  getSupplierDeliveryChangesNewODRs,
  getOneWeekODRs,
  getOneWeekAtRiskODRs,
  // getNncODRs
} from 'services/meeting-management-services/odrStageDashboardService';

import {
  // getNNCNewCCFs,
  getThisWeekNewCCFs,
  getTodayTradeDDC,
  getTodayCommercialDDC,
  getNcDdc
} from 'services/meeting-management-services/cusFocusSumaryService';

const CustomersFocusExportWidget = ({
  details,
  atRisksODRs,
  agedODRs,
  newODRs,
  newCCFs,
  ncCustomers,
  faultSource,
  newAtRiskODRs,
  agedAtRiskODRs,
  actionPlans,
  missingInfoCcfs,
  // isLoading,
  metrics
}) => {
  const [supplierDeliveryChangesAgedODRs, setSupplierDeliveryChangesAgedODRs] =
    useState({ data: -1, url: '' });
  const [supplierDeliveryChangesNewODRs, setSupplierDeliveryChangesNewODRs] =
    useState({ data: -1, url: '' });
  const [oneWeekODRs, setOneWeekODRs] = useState({ data: -1, url: '' });
  const [oneWeekAtRiskOdrs, setOneWeekAtRiskOdrs] = useState({
    data: -1,
    url: ''
  });
  const [thisWeekNewCcfs, setThisWeekNewCcfs] = useState({ data: -1, url: '' });
  const [nncCCFs, setNncCCFs] = useState({ data: -1, url: '' });
  const [nncOdrs, setNncOdrs] = useState({ data: -1, url: '' });
  const [nncDdc, setNncDdc] = useState({ data: -1, url: '' });
  const [todayTradeDDC, setTodayTradeDDC] = useState({
    data: -1,
    url: ''
  });
  const [todayCommercialDDC, setTodayCommercialDDC] = useState({
    data: -1,
    url: ''
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      getSupplierDeliveryChangesAgedODRs(),
      getSupplierDeliveryChangesNewODRs(),
      getOneWeekODRs(),
      getOneWeekAtRiskODRs(),
      getThisWeekNewCCFs(),
      getTodayCommercialDDC(),
      getTodayTradeDDC(),
      getNcDdc()
    ])
      .then(
        ([
          sdcAgedODRs,
          sdcnewODRs,
          oneWeekODRsRs,
          oneWeekAtRiskOdrsRs,
          thisWeekNewCcfsRs,
          // nncCcfsRs,
          // nncOdrsRs,
          todayCommercialDDCRs,
          todayTradeDDCRs,
          nncDdcRs,
        ]) => {
          setSupplierDeliveryChangesAgedODRs(sdcAgedODRs);
          setSupplierDeliveryChangesNewODRs(sdcnewODRs);
          setOneWeekODRs(oneWeekODRsRs);
          setOneWeekAtRiskOdrs(oneWeekAtRiskOdrsRs);
          setThisWeekNewCcfs(thisWeekNewCcfsRs);
          const nncCcfsRs = newCCFs.data.filter(x=> x.isNewCritical);
          setNncCCFs({data: nncCcfsRs});
          const nncOdrsRs = [...agedODRs.data.filter(x=> x.isNewCritical),...newODRs.data.filter(x=> x.isNewCritical)];
          setNncOdrs({data:nncOdrsRs});
          setTodayCommercialDDC(todayCommercialDDCRs);
          setTodayTradeDDC(todayTradeDDCRs);
          setNncDdc(nncDdcRs)
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const [comments, setComments] = useState([]);
  useEffect(() => {
    fetchComments();
  }, [actionPlans]);

  const fetchComments = async () => {
    try {
      if (!actionPlans) return;
      const res = await searchComments({
        isGetAll: true,
        ActionPlanIds: actionPlans.map(x => x.id)
      });
      if (res) {
        setComments(res.items);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Row>
        <Col
          xs={12}
          md={4}
          className="mb-4 d-flex align-items-center justify-content-start"
        >
          {!isLoading && (
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="eye"
              transform="shrink-3"
              className="mx-2"
              onClick={() => {
                exportMsWork({
                  ...details,
                  atRisksODRs,
                  agedODRs,
                  newODRs,
                  newCCFs,
                  ncCustomers,
                  faultSource,
                  newAtRiskODRs,
                  agedAtRiskODRs,
                  actionPlans,
                  comments,
                  supplierDeliveryChangesAgedODRs,
                  supplierDeliveryChangesNewODRs,
                  oneWeekODRs,
                  oneWeekAtRiskOdrs,
                  thisWeekNewCcfs,
                  nncCCFs,
                  nncOdrs,
                  todayTradeDDC,
                  todayCommercialDDC,
                  nncDdc,
                  missingInfoCcfs,
                  metrics
                });
              }}
            >
              <span className="d-none d-sm-inline-block ms-1">Preview</span>
            </IconButton>
          )}
          {!isLoading && (
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="envelope"
              transform="shrink-3"
              className="mx-2"
              onClick={() =>
                swalConfirm(
                  'Are you sure you want to sent the email?',
                  'This action cannot be undone.',
                  () => {
                    sentMailWithAttactment({
                      ...details,
                      atRisksODRs,
                      agedODRs,
                      newODRs,
                      newCCFs,
                      ncCustomers,
                      faultSource,
                      newAtRiskODRs,
                      agedAtRiskODRs,
                      actionPlans,
                      comments,
                      supplierDeliveryChangesAgedODRs,
                      supplierDeliveryChangesNewODRs,
                      oneWeekODRs,
                      oneWeekAtRiskOdrs,
                      thisWeekNewCcfs,
                      nncCCFs,
                      nncOdrs,
                      todayTradeDDC,
                      todayCommercialDDC,
                      nncDdc,
                      missingInfoCcfs,
                      metrics
                    });
                  }
                )
              }
            >
              <span className="d-none d-sm-inline-block ms-1">
                Send Meeting Report Email
              </span>
            </IconButton>
          )}
        </Col>
      </Row>
    </>
  );
};

CustomersFocusExportWidget.propTypes = {
  atRisksODRs: PropTypes.object,
  agedODRs: PropTypes.object,
  newODRs: PropTypes.object,
  newCCFs: PropTypes.object,
  ncCustomers: PropTypes.object,
  faultSource: PropTypes.array,
  newAtRiskODRs: PropTypes.object,
  agedAtRiskODRs: PropTypes.object,
  actionPlans: PropTypes.array,
  isLoading: PropTypes.bool,
  details: PropTypes.object,
  missingInfoCcfs: PropTypes.object,
  metrics: PropTypes.array,
};

export default CustomersFocusExportWidget;
