import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { searchComments } from 'services/customer-service/scActionPlanComment';
import IconButton from 'components/common/IconButton';
import {
  exportMsWork,
  sentMailWithAttactment
} from 'services/meeting-management-services/meetingReportService';
import { swalConfirm } from 'components/utilities/SwalToastUtil';


const MeetingExportWidget = ({ details, actionPlans }) => {
  const [comments, setComments] = useState([]);
  useEffect(() => {
    fetchComments();
  }, [actionPlans]);

  const fetchComments = async () => {
    try {
      if (!actionPlans) return;
      const res = await searchComments({
        isGetAll: true,
        ActionPlanIds: actionPlans.map(x => x.id)
      });
      if (res) {
        setComments(res.items);
      }
    } catch (err) {
      console.log(err);
    }
  };
  console.log(details)

  return (
    <>
      <Col
        xs={12}
        md={4}
        className="mb-4 d-flex align-items-center justify-content-start"
      >
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="eye"
          transform="shrink-3"
          className="mx-2"
          onClick={() => {
            exportMsWork({
              ...details,
              actionPlans,
              comments
            });
          }}
        >
          <span className="d-none d-sm-inline-block ms-1">Preview</span>
        </IconButton>
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="envelope"
          transform="shrink-3"
          className="mx-2"
          onClick={() =>
            swalConfirm(
              'Are you sure you want to sent the email?',
              'This action cannot be undone.',
              () => {
                sentMailWithAttactment({
                  ...details,
                  actionPlans,
                  comments
                });
              }
            )
          }
        >
          <span className="d-none d-sm-inline-block ms-1">
            Send Meeting Report Email
          </span>
        </IconButton>
      </Col>
    </>
  );
};

MeetingExportWidget.propTypes = {
  atRisksODRs: PropTypes.object,
  agedODRs: PropTypes.object,
  newODRs: PropTypes.object,
  newCCFs: PropTypes.object,
  ncCustomers: PropTypes.object,
  faultSource: PropTypes.object,
  newAtRiskODRs: PropTypes.object,
  agedAtRiskODRs: PropTypes.object,
  actionPlans: PropTypes.array,
  isLoading: PropTypes.bool,
  details: PropTypes.object
};

export default MeetingExportWidget;
