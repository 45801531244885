import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ColumnHeader from './ColumnHeader';
import dayjs from 'dayjs';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { groupBy } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons';

const Column = ({ dept, columnItems, setShow, setModalInfo, search }) => {
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const { name, id, data } = dept;
  const searchRef = useRef(null);
  let hack = 0; // Need to get distinct customers list - avoid dupes


  const [defaultColumnItems, setDefaultColumnItems] = useState(dept.id === 4126 ? columnItems.filter(x => !x.preContractPresented) : columnItems);

  const count =
    dept.id === 4126
      ? defaultColumnItems?.length
      : defaultColumnItems?.flatMap(x => x?.jobs)?.length;
  const refs = {};

  const openModal = info => {
    setModalInfo({ ...info });
    setShow(true);
  };

  useEffect(() => {
    searchRef?.current?.click();
  }, [searchRef]);

  useEffect(() => {
    if (id === 4126) {
      if (showAll) {
        setDefaultColumnItems(columnItems)
      } else {
        setDefaultColumnItems(columnItems.filter(x => !x.preContractPresented))
      }
    }
  }, [showAll]);

  const generateJobDetails = jobs => {
    return (
      <>
        {jobs.map(y => {
          console.log(y)
          return (
            <div
              key={`job-item-${y.jobNo}`}
              className="text-center rounded-3 border py-2 px-1 mb-1 shadow-sm bg-100"
              style={{ width: '90%' }}
            >
              {y.jobNo && (
                <div>
                  {(id === 4135 || id === 5554) && (
                    <Form.Check
                      className="d-inline float-start"
                      value={y.jobNo}
                      ref={ref => (refs[y.jobNo] = ref)}
                      onChange={({ target: { value, checked } }) => {
                        if (checked) {
                          if (
                            selectedJobs.length &&
                            selectedJobs.every(
                              x => x.customerCode !== y.customerCode
                            )
                          ) {
                            toast.error(
                              'Must select jobs from the same customer!'
                            );
                            refs[y.jobNo].checked = false;
                            return;
                          }
                          setSelectedJobs(prev => [
                            ...prev,
                            {
                              jobno: value,
                              customerCode: y.customerCode
                            }
                          ]);
                        } else
                          setSelectedJobs(prev =>
                            prev.filter(x => x.jobno !== value)
                          );
                      }}
                    />
                  )}
                  {y.boughtInFrame ? <span className='float-end'><FontAwesomeIcon className='text-warning' icon={faShoppingBasket} /></span> : ''}
                  {y.jobNo}
                </div>
              )
              }
              <div
                ref={search && search === y.jobNo ? searchRef : null}
                onClick={() =>
                  openModal({
                    customerCode: y.customerCode,
                    customerName: y.customerName,
                    jobNo: y.jobNo,
                    stageId: id,
                    stageName: name
                  })
                }
              >
                {y.jobNo && (
                  <div>
                    <strong>
                      <span className="w-50 d-inline-block text-truncate">
                        {y.customerName}
                      </span>
                      <span className="d-inline-block text-truncate">
                        ({y.customerCode})
                      </span>
                    </strong>
                  </div>
                )}
                {y.reference && (
                  <div>
                    <span className="w-50 d-inline-block text-truncate">
                      {y.reference}
                    </span>
                  </div>
                )}
              </div>
            </div >
          );
        })}
      </>
    );
  };

  return (
    <div style={{ width: '25rem' }} className={classNames('kanban-column')}>
      <ColumnHeader
        id={id}
        title={name}
        itemCount={count}
        colour={data}
        selectedJobs={selectedJobs}
        showAll={setShowAll}
      />
      <>
        <div
          id={`container-${id}`}
          className="kanban-items-container scrollbar"
        >
          {defaultColumnItems && defaultColumnItems.length > 0 ? (
            defaultColumnItems.map((x, idx) => {
              const outerKey =
                id === 4126
                  ? `cust-item-${x.key}-${hack++}`
                  : `job-item-key-${idx}`;
              const hasJobs = x.jobs && x.jobs.length;
              const title = isNaN(dayjs(x.key).date())
                ? x.key
                : dayjs(x.key).format('dddd DD MMMM');

              let jobsGroupedByCustomer = [];
              if (id == 4134 || id == 4135 || id == 5554 || id == 5445) {
                jobsGroupedByCustomer = groupBy(x.jobs, 'customerName');
              }

              return (
                <div
                  key={outerKey}
                  className="d-flex flex-column justify-content-center align-items-center rounded-3 border py-2 mb-1 px-auto"
                  style={{ backgroundColor: '#e1e6e6' }}
                  onClick={() =>
                    hasJobs
                      ? undefined
                      : openModal({
                        customerCode: x.customerCode,
                        customerName: x.customerName,
                        stageId: id,
                        stageName: name
                      })
                  }
                >
                  {!hasJobs ? (
                    <>
                      <div className="mb-3 fw-bold">
                        {x.customerCode ?? 'N/A'}
                      </div>
                      <div className="d-inline-block text-center text-truncate w-100">
                        {x.customerName ?? ''}
                      </div>
                      <div>{x.priorityType}</div>
                    </>
                  ) : (
                    <>
                      <div className="mb-3 fw-bold" style={{ fontSize: 16 }}>
                        {title}
                      </div>
                      {(id == 4134 || id == 4135 || id == 5554 || id == 5445) &&
                        jobsGroupedByCustomer.length > 0 &&
                        jobsGroupedByCustomer.map((job, index) => {
                          return (
                            <div
                              style={{ minWidth: '90%' }}
                              key={index}
                              className="d-flex flex-column justify-content-center align-items-center rounded-3 border py-2 mb-1 px-auto shadow-sm bg-soft-secondary"
                            >
                              <div className="mb-3 fw-bold">{job.key}</div>
                              {generateJobDetails(job.jobs)}
                            </div>
                          );
                        })}
                      {id != 4134 &&
                        id != 4135 &&
                        id != 5554 &&
                        id != 5445 &&
                        generateJobDetails(x.jobs)}
                    </>
                  )}
                </div>
              );
            })
          ) : (
            <div className="text-center rounded-3 py-2 mb-1">No Data</div>
          )}
        </div>
      </>
    </div>
  );
};

Column.propTypes = {
  dept: PropTypes.object,
  columnItems: PropTypes.array,
  setShow: PropTypes.func,
  setModalInfo: PropTypes.func,
  search: PropTypes.string
};

export default Column;
