import { parseUserToDropdownValue } from 'services/userServices';
import { EVA_URL } from '../../helpers/urlHelper';
import moment from 'moment';
import {
  previewDocument,
  buildTableXml,
  getBlob
} from 'helpers/wordExportHelper';
import { splitStringMapValueToDropDown } from 'helpers/dropdownFuncHelper';
import { progresses } from 'data/TempData';
import { getAllEvaUsers } from 'services/userServices';
import { swalToastError, swalToastSuccess } from 'components/utilities/SwalToastUtil';

const buildParamOfAttachment = async data => {
  let attendees = (await parseUserToDropdownValue(data.participants))
    .map(participant => participant.name.trim())
    .join('\n');
  let actionPlanXml = await buildMMActionPlanXML(data);
  const param = {
    date: moment().format('DD/MM/YYYY'),
    attendees,
    meetingTitle: data?.title,
    objective: data?.description ?? "",
    actionPlanXml: actionPlanXml,
  };
  return param;
};

const buildMMActionPlanXML = async (data) => {
  const actionPlanHeaders = [
    { headerText: 'Action Number', value: 'id' },
    { headerText: 'Description of Action', value: 'description' },
    { headerText: 'Date added', value: 'created' },
    { headerText: 'Assigned to', value: 'requestParticipants' },
    { headerText: 'Target Date for completion', value: 'targetDate' },
    {
      headerText: 'Customer Communication Status',
      value: 'customerCommStatus'
    },
    { headerText: 'Progress Status', value: 'progress' },
    { headerText: 'Additional Comments', value: 'additionalComments' },
    { headerText: 'Feedback Generated', value: 'feedbackGenerated' }
  ];

  const users = await getAllEvaUsers();
  const actionPlanXml = buildTableXml(
    actionPlanHeaders,
    await Promise.all(
      data.actionPlans.map(async plan => {
        const participants = splitStringMapValueToDropDown(
          plan.requestParticipants,
          users
        )
          .map(user => user.name)
          .join(', ');
        const status = splitStringMapValueToDropDown(
          plan.progress.toString(),
          progresses
        )
          .map(x => x.label)
          .join(', ');
        const commentText = data.comments
          .filter(x => x.actionPlanId == plan.id)
          .map(x => {
            const text = parseHtmlToText(x.description);
            console.log(users);
            const userName = users.find(u => u.userId == x.createdBy).name;

            return userName + ' : ' + text;
          })
          .join(', \n\r');
        return {
          ...plan,
          created: moment(plan.created).format('DD/MM/YYYY'),
          targetDate: moment(plan.targetDate).format('DD/MM/YYYY'),
          description: parseHtmlToText(plan.description),
          requestParticipants: participants,
          progress: status,
          additionalComments: commentText
        };
      })
    )
  );
  return actionPlanXml;
}

const templatePath = '/files/TemplateOtherMeeting.docx';
const fileName = 'ActionPlan_Report.docx';

const exportMsWork = async (data) => {
  const param = await buildParamOfAttachment(data);
  previewDocument(templatePath, param, fileName);
};


const sentMailWithAttactment = async data => {
  const param = await buildParamOfAttachment(data);
  const blob = await getBlob(templatePath, param);
  const formData = new FormData();
  formData.append('files', blob, fileName);

  let participantsArray = data?.participants?.split(',');
  if (!participantsArray || participantsArray.length == 0) {
    swalToastError('Participants persons are empty');
    return;
  }

  participantsArray.forEach(item => {
    formData.append('toByIds', item);
  });

  formData.append('fromByIds', 2);

  const bodyRes = await fetch('/files/MailTemplateOtherMeeting.html');
  if (bodyRes.ok) {
    let bodyText = await bodyRes.text();
    const formattedDate = moment().format('DD/MM/YYYY');
    const currentYear = moment().format('YYYY');

    bodyText = bodyText
      .replace('{{current_date}}', formattedDate)
      .replace('{{current_year}}', currentYear)
      .replaceAll('{{title}}', data?.title ?? '')
      .replaceAll('{{description}}', data?.description ?? '');

    formData.append('Body', bodyText);
  } else {
    console.error('Failed to fetch the HTML template.');
    return;
  }

  formData.append(
    'Subject',
    data?.title  + ' ' +
      moment(new Date()).format('DD/MM/YYYY')
  );

  const apiResponse = await fetch(`${EVA_URL}/emails/send-with-attachments`, {
    method: 'POST',
    body: formData,
    credentials: 'include'
  });

  if (!apiResponse.ok) {
    throw new Error('Failed to send document to API');
  }

  swalToastSuccess("Document sent successfully");
};

export { exportMsWork, sentMailWithAttactment, parseHtmlToText };

const parseHtmlToText = htmlString => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  return doc.body.textContent || '';
};
