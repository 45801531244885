import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import BasicTemplate from 'components/Toast/BasicTemplate';
import BaseDropdown from 'components/Dropdowns/BaseDropdown';
import { RRule } from 'rrule';
import moment from 'moment';

const weekday = [
  { display: 'M', id: RRule.MO },
  { display: 'T', id: RRule.TU },
  { display: 'W', id: RRule.WE },
  { display: 'T', id: RRule.TH },
  { display: 'F', id: RRule.FR },
  { display: 'S', id: RRule.SA },
  { display: 'S', id: RRule.SU }
];

const RecurrenceModal = ({ setRRule, rRuleParam, setScheduleStartDate }) => {
  const [rRuleObj, setRRuleObj] = useState(new RRule({}));
  useEffect(() => {
    setRRuleObj(new RRule(rRuleParam));
  }, [rRuleParam]);

  console.log(rRuleObj);
  const handleByWeekDay = day => {
    const existed = rRuleObj.origOptions.byweekday?.filter(i => day.id == i);
    let newByWeekDay = rRuleObj.origOptions.byweekday
      ? rRuleObj.origOptions.byweekday
      : [];
    console.log(newByWeekDay);
    if (existed && existed.length > 0) {
      newByWeekDay = rRuleObj.origOptions.byweekday.filter(i => day.id !== i);
    } else {
      newByWeekDay.push(day.id);
    }
    handleChange('byweekday', [...newByWeekDay]);
  };

  const handleChange = (key, value) => {
    if (
      key == 'until' &&
      moment(value).diff(moment(rRuleObj.options.dtstart), 'years', true) > 200
    ) {
      toast.error(
        <BasicTemplate success={false} text={'Please enter a year within the range of 200 years'} />
      );
      return;
    }
    let newRule = {
      freq: rRuleObj.options.freq,
      dtstart: rRuleObj.options.dtstart,
      until: rRuleObj.options.until,
      count: rRuleObj.options ? rRuleObj.options.count : 0,
      byweekday: rRuleObj.origOptions.byweekday,
      interval: rRuleObj.options.interval
    };

    newRule[key] = value;
    if (key == 'freq' && value != RRule.WEEKLY) {
      newRule.byweekday = undefined;
      newRule.count = 10;
    }

    if (key == 'dtstart') {
      const originalDate = moment(value, 'ddd MMM DD YYYY HH:mm:ss ZZ');
      const isoString = originalDate.toISOString();
      const date = moment.utc(isoString).toDate();

      newRule[key] = date;

      setScheduleStartDate(value);

      if (newRule['freq'] == RRule.WEEKLY) {
        if (
          rRuleObj.origOptions.byweekday &&
          rRuleObj.origOptions.byweekday.length > 0
        ) {
          newRule.byweekday = rRuleObj.origOptions.byweekday;
        } else {
          const utcScheduleStart = moment.utc(newRule.dtstart.toISOString());
          const wd = weekday.find(i => i.id.weekday == utcScheduleStart.day());
          const byweekday = wd ? [wd.id] : undefined;
          newRule.byweekday = byweekday;
        }
      }
    }

    setRRule({ ...newRule });
  };

  return (
    <Row
      style={{
        margin: '40px',
        padding: '10px',
        border: 'solid 1px',
        borderRadius: '5px'
      }}
    >
      <Form.Group as={Row} controlId="frequency">
        <Form.Label column="true" className="fs-0 col-3">
          Set recurrence
        </Form.Label>
      </Form.Group>

      <Form.Group
        style={{
          paddingBottom: '10px'
        }}
        as={Row}
        controlId="start_date"
      >
        <Form.Label column="true" className="fs-0 col-3 required">
          Start
        </Form.Label>
        <Col className="col-9">
          <DatePicker
            selected={
              rRuleObj.options
                ? moment.utc(rRuleObj.options.dtstart).toDate()
                : null
            }
            onChange={date => handleChange('dtstart', date)}
            className="form-control"
            placeholderText="MM-DD-YYYY H:M"
            dateFormat="dd-MM-yyyy h:mm aa"
            showTimeSelect
          />
        </Col>
      </Form.Group>

      <Form.Group
        as={Row}
        controlId="frequency"
        style={{ display: 'flex', paddingBottom: '10px' }}
      >
        <Form.Label column="true" className="col-3 required">
          Repeat every
        </Form.Label>
        <Col
          className="col-9"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Form.Control
            style={{
              marginRight: '10px',
              width: '100px'
            }}
            type="number"
            name="interval"
            required
            value={rRuleObj.options ? rRuleObj.options.interval : 0}
            onChange={e => handleChange('interval', e.target.value)}
          />
          <BaseDropdown
            sources={[
              { id: RRule.DAILY, display: 'Day' },
              { id: RRule.WEEKLY, display: 'Week' },
              { id: RRule.MONTHLY, display: 'Month' },
              { id: RRule.YEARLY, display: 'Year' }
            ]}
            selectedOptions={
              rRuleObj.options ? rRuleObj.options.freq : RRule.WEEKLY
            }
            handleChange={opts => handleChange('freq', opts.id)}
            isMulti={false}
          />
        </Col>
      </Form.Group>

      {rRuleObj.options?.freq == RRule.WEEKLY ? (
        <Form.Group as={Row} controlId="frequency" style={{ display: 'flex' }}>
          <Form.Label column="true" className="col-3"></Form.Label>
          {weekday.map((i, index) => {
            return (
              <Col
                key={index}
                className="col-1"
                style={{ padding: '10px' }}
                onClick={() => {
                  handleByWeekDay(i);
                }}
              >
                <div className="avatar avatar-xl">
                  <div
                    className="rounded-circle"
                    style={{
                      textAlign: 'center',
                      fontWeight: '600',
                      backgroundColor: rRuleObj?.origOptions?.byweekday?.some(
                        x => x == i.id
                      )
                        ? 'blue'
                        : ''
                    }}
                  >
                    {i.display}
                  </div>
                </div>
              </Col>
            );
          })}
        </Form.Group>
      ) : (
        ''
      )}

      <Form.Group
        as={Row}
        controlId="frequency"
        style={{ display: 'flex', paddingBottom: '10px' }}
      >
        <Form.Label column="true" className="col-3">
          End
        </Form.Label>
        <Col className="col-9">
          <DatePicker
            required
            selected={rRuleObj.options ? rRuleObj.options.until : null}
            onChange={date => {
              handleChange('until', date);
              // setRRule({ ...rRule, until: date });
            }}
            className="form-control"
            placeholderText="MM-DD-YYYY H:M"
            dateFormat="dd-MM-yyyy h:mm aa"
            showTimeSelect
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="frequency">
        <Form.Label column="true" className="col-3"></Form.Label>
        <Col className="col-9">
          Occurs {rRuleObj ? rRuleObj.toText() : ''}
          {' starting ' +
            moment(rRuleObj.options.dtstart).format('MM/DD/yyyy hh:mm')}
        </Col>
      </Form.Group>
    </Row>
  );
};

RecurrenceModal.propTypes = {
  setRRule: PropTypes.func,
  rRuleParam: PropTypes.object,
  setScheduleStartDate: PropTypes.func,
  setInitialEvents: PropTypes.func,
  initialEvents: PropTypes.array
};

export { RecurrenceModal, weekday };
