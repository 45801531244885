import axios from 'axios';
import { EVA_URL } from '../../helpers/urlHelper';

axios.defaults.withCredentials = true;

// const baseURL = `https://eva.emplas.co.uk/Ajax/ODR/GetODRByStage.ashx`;
const baseURL = `${EVA_URL}/eva-odr-dashboard`;
const fullState = [
  '1879',
  '2087',
  '2069',
  '1880',
  '1937',
  '2962',
  '4941',
  '2327',
  '2511',
  '2512',
  '2513',
  '2514',
  '2515',
  '3338',
  '6237',
  '4976',
  '5837',
  '2970',
  '2949',
  '2562',
  '2328',
  '2329',
  '2497',
  '2510'
];

const requestBase = {
  stages: fullState.join(','), // Convert array to comma-separated string
  query: '',
  users:
    '2441,1346,4453,1498,4343,17,1742,4621,544,3600,3689,1492,269,3856,1350,5109,3696,4031,4597,1743,1731,5783,2554,2580,2091,3557,243,1325,4,55,578,581,4234,4387,3681,4288,1635,4289,1256,605,2200,3653,3646,3650,264,318,224,3871,614,2144,1437,4526,1528,2469,454,3695,1,2435,4754',
  customerTypes: '902,147,889,146',
  odrAge: '',
  odrAtRisk: 'atrisk'
};

const evaLink =
  process.env.REACT_APP_EVA_URL + '/Internal/ODR/ODRStageDashboard.aspx';

const getOneWeekODRs = async () => {
  try {
    const response = await axios({
      method: 'post',
      url: `${baseURL}/get-one-week-odrs`,
      data: { ...requestBase, odrAtRisk: '' },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    if (
      response &&
      response.data.success &&
      response.data.data !== null &&
      response.data.data !== undefined
    ) {
      const params = new URLSearchParams(requestBase);
      return {
        data: response.data.data,
        url: `${evaLink}?${params.toString()}`
      };
    } else {
      return 0;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return 0;
  }
};

const getOneWeekAtRiskODRs = async () => {
  try {
    const response = await axios({
      method: 'post',
      url: `${baseURL}/get-one-week-odrs`,
      data: { ...requestBase, odrAtRisk: 'atrisk' },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    if (
      response &&
      response.data.success &&
      response.data.data !== null &&
      response.data.data !== undefined
    ) {
      const params = new URLSearchParams(requestBase);
      return {
        data: response.data.data,
        url: `${evaLink}?${params.toString()}`
      };
    } else {
      return 0;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return 0;
  }
};

const getOdrBase= async (url) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${baseURL}/${url}`,
      // data: requestBase,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    console.log(response)
    if (
      response &&
      response.data.success &&
      response.data.data !== null &&
      response.data.data !== undefined
    ) {
      const params = new URLSearchParams(response.data.data.paramObj);
      return {
        data: response.data.data.items,
        url: `${evaLink}?${params.toString()}`
      };
    } else {
      return 0;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return 0;
  }
} 
const getAtRiskODRs = async () => {
  // return getOdrBase('get-at-risk-odrs');
   try {
     const response = await axios({
       method: 'get',
       url: `${baseURL}/get-at-risk-odrs`,
       // data: requestBase,
       headers: {
         Accept: 'application/json',
         'Content-Type': 'application/json'
       }
     });

     console.log(response)
     if (
       response &&
       response.data.success &&
       response.data.data !== null &&
       response.data.data !== undefined
     ) {
       const params = new URLSearchParams(response.data.data.paramObj);
       return {
         data: response.data.data.items,
         url: `${evaLink}?${params.toString()}`
       };
     } else {
       return 0;
     }
   } catch (error) {
     console.error('Error fetching data:', error);
     return 0;
   }
};
const getFaultSourceODRs = async () => {
  const exCludes = [
    'Commercial Contractor – Charge back ',
    'Commercial Supply Only',
  ]
  try {
    const response = await axios({
      method: 'get',
      url: `${EVA_URL}/OptionList?optionKey=wd.odr.fault.source&enable=true`,
      data: requestBase,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.data?.filter(x => !exCludes.includes(x.name));
  } catch (error) {
    console.error('Error fetching data:', error);
    return 0;
  }
};

const getSupplierDeliveryChanges = async () => {
  return getOdrBase('get-supplier-delivery-changes');
};

const getSupplierDeliveryChangesAgedODRs = async () => {
  try {
    const request = {
      ...requestBase,
      stages: '6237',
      odrAtRisk: '',
      odrAge: 'old'
    };
    const response = await axios({
      method: 'post',
      url: `${baseURL}/get-odrs`,
      data: request,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    if (
      response &&
      response.data.success &&
      response.data.data !== null &&
      response.data.data !== undefined
    ) {
      const params = new URLSearchParams(request);
      return {
        data: response.data.data,
        url: `${evaLink}?${params.toString()}`
      };
    } else {
      return 0;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return 0;
  }
};

const getAgedODRs = async () => {
  // return getOdrBase('get-aged-odrs');
  try {
    const request = { ...requestBase, odrAge: 'old', odrAtRisk: '' };
    const response = await axios({
      method: 'post',
      url: `${baseURL}/get-odrs`,
      data: request,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    if (
      response &&
      response.data.success &&
      response.data.data !== null &&
      response.data.data !== undefined
    ) {
      const params = new URLSearchParams(request);
      return {
        data: response.data.data,
        url: `${evaLink}?${params.toString()}`
      };
    } else {
      return 0;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return 0;
  }
};

const getNncODRs = async () => {
  try {
    const request = { ...requestBase, odrAge: '', odrAtRisk: '' };
    const response = await axios({
      method: 'post',
      url: `${baseURL}/get-nc-odrs`,
      data: request,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    if (
      response &&
      response.data.success &&
      response.data.data !== null &&
      response.data.data !== undefined
    ) {
      const params = new URLSearchParams(request);
      return {
        data: response.data.data,
        url: `${evaLink}?${params.toString()}`
      };
    } else {
      return 0;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return 0;
  }
};

const getAgedAtRiskODRs = async () => {
  try {
    const request = { ...requestBase, odrAge: 'old', odrAtRisk: 'atrisk' };
    const response = await axios({
      method: 'post',
      url: `${baseURL}/get-odrs`,
      data: request,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    if (
      response &&
      response.data.success &&
      response.data.data !== null &&
      response.data.data !== undefined
    ) {
      const params = new URLSearchParams(request);
      return {
        data: response.data.data,
        url: `${evaLink}?${params.toString()}`
      };
    } else {
      return 0;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return 0;
  }
};

const getNewODRs = async () => {
  // return getOdrBase('get-new-odrs');
  try {
    const request = { ...requestBase, odrAge: 'new', odrAtRisk: '' };
    const response = await axios({
      method: 'post',
      url: `${baseURL}/get-odrs`,
      data: request,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    if (
      response &&
      response.data.success &&
      response.data.data !== null &&
      response.data.data !== undefined
    ) {
      const params = new URLSearchParams(request);
      return {
        data: response.data.data,
        url: `${evaLink}?${params.toString()}`
      };
    } else {
      return 0;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return 0;
  }
};

const getSupplierDeliveryChangesNewODRs = async () => {
  try {
    const request = {
      ...requestBase,
      stages: '6237',
      odrAtRisk: '',
      odrAge: 'new'
    };
    const response = await axios({
      method: 'post',
      url: `${baseURL}/get-odrs`,
      data: request,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    if (
      response &&
      response.data.success &&
      response.data.data !== null &&
      response.data.data !== undefined
    ) {
      const params = new URLSearchParams(request);
      return {
        data: response.data.data,
        url: `${evaLink}?${params.toString()}`
      };
    } else {
      return 0;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return 0;
  }
};

const getNewAtRiskODRs = async () => {
  try {
    const request = { ...requestBase, odrAge: 'new', odrAtRisk: 'atrisk' };
    const response = await axios({
      method: 'post',
      url: `${baseURL}/get-odrs`,
      data: request,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    if (
      response &&
      response.data.success &&
      response.data.data !== null &&
      response.data.data !== undefined
    ) {
      const params = new URLSearchParams(request);
      return {
        data: response.data.data,
        url: `${evaLink}?${params.toString()}`
      };
    } else {
      return 0;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return 0;
  }
};

export {
  getAtRiskODRs,
  getSupplierDeliveryChanges,
  getSupplierDeliveryChangesAgedODRs,
  getSupplierDeliveryChangesNewODRs,
  getAgedODRs,
  getNewODRs,
  getFaultSourceODRs,
  getAgedAtRiskODRs,
  getNewAtRiskODRs,
  getOneWeekODRs,
  getOneWeekAtRiskODRs,
  getNncODRs,
};
