import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, ButtonGroup } from 'react-bootstrap';
import AppContext from 'context/Context';
import {
  post,
  patch,
  search
} from 'services/bs-sector-measurable/measurableService';
import OptionListSelect from 'components/Dropdowns/OptionListSelect';
import { inputPopulateType } from 'data/TempData';
import FormulaEditor from 'components/common/FormulaEditor';


const MeasurableModal = ({
  measurables,
  data,
  isShow,
  handleClose,
  handleSubmit
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  useEffect(() => {
    setFormData(data);
  }, [data]);

  useEffect(() => {
    const data = measurables?.map(x => {
      return { id: x.id, name: x.name };
    });
    setField(data);
  }, [measurables]);

  const [formData, setFormData] = useState(data);
  const [fields, setField] = useState([]);
  const handleChange = ({ target }) => {
    console.log(target);
    let name = target.name;
    let value = target.value;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async e => {
    console.log('submit');
    e.preventDefault();

    const rq = {...formData, formula};

    if (rq.id > 0) {
      await patch(rq.id, { ...rq });
    } else {
      await post({ ...rq });
    }
    handleSubmit();
  };

  const handleOptionChange = e => {
    setFormData({ ...formData, inputMethod: parseInt(e.target.value, 10) });
  };

  useEffect(() => {
    search({ isGetAll: true, sectorId: data.sectorId }).then(res => {
      console.log(res);
    });
  }, []);

  const [formula, setFormula] = useState(formData.formula);
  const saveFormula = (formulaWithNames, formulaWithIds) => {
    setFormula(JSON.stringify({ formulaWithNames, formulaWithIds }))
  };

  return (
    <Modal show={isShow} onHide={handleClose} contentClassName="border">
      <Form onSubmit={onSubmit}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-card border-bottom-0"
        >
          <Modal.Title as="h5">
            {formData.id > 0 ? 'Edit' : 'Create'} Measurable
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-card">
          <Form.Group className="mb-3" controlId="titleInput">
            <Form.Label className="fs-0">Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              required
              value={formData?.name}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="targetType">
            <Form.Label className="fs-0">Target Type</Form.Label>
            <OptionListSelect
              optionKey="balance.score.card.targettype"
              selected={
                formData && formData.targetType ? formData.targetType : 0
              }
              changeFn={opt =>
                handleChange({
                  target: { name: 'targetType', value: opt.value }
                })
              }
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="variationPercentage">
            <Form.Label className="fs-0">
              Target Variation Percentage
            </Form.Label>
            <Form.Control
              type="number"
              name="variationPercentage"
              required
              value={formData?.variationPercentage}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="variationPercentage">
            <Form.Label className="fs-0">Target Value Input</Form.Label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'left',
                gap: '10px',
                padding: '10px'
              }}
            >
              {inputPopulateType.map(option => (
                <ButtonGroup
                  key={option.value}
                  className="btn-group-navbar-style"
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Form.Label
                    htmlFor={`option-${option.value}`}
                    className="cursor-pointer hover-overlay"
                    style={{
                      marginBottom: '0',
                      paddingRight: '5px'
                    }}
                  >
                    {option.label}
                  </Form.Label>
                  <Form.Check
                    type="radio"
                    id={`option-${option.value}`}
                    name="dataPopulate"
                    value={option.value}
                    onChange={handleOptionChange}
                    checked={formData?.inputMethod === option.value}
                    style={{
                      margin: '0'
                    }}
                  />
                </ButtonGroup>
              ))}
            </div>

            {formData.inputMethod === 3 && <FormulaEditor
              formulaString={
                JSON.parse(formula)?.formulaWithIds
              }
              fields={fields?.filter(x => x.id != formData.id)}
              saveFormula={saveFormula}
            />}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light px-card border-top-0">
          <Button
            variant="primary"
            type="submit"
            onClick={onsubmit}
            className="px-4 mx-0"
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

MeasurableModal.propTypes = {
  measurables: PropTypes.array,
  data: PropTypes.object,
  isShow: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default MeasurableModal;
