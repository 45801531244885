import React, { useState, useEffect } from 'react';
import PageHeader from 'components/common/PageHeader';
import { Card, Col, Form, FormLabel, Row } from 'react-bootstrap';
import '../../../../../../assets/scss/user.scss';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SectorCard from './SectorCard';
import { getOptionsByKey } from 'services/optionLists';
import { search } from 'services/bs-sector-measurable/measurableService';

export default function BalancedScorecard() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [previousWeeks, setPreviousWeeks] = useState(4);

  const [sectors, setSectors] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const controller = new AbortController();
    let resOptions = await getOptionsByKey(
      'balance.score.card.sector',
      controller.signal
    );
    if (resOptions && resOptions.length) {
      let resSectorMeasurables = await search({ isGetAll: true });
      let mappedData = resOptions.map(x => {
        let sectorMeasurables = resSectorMeasurables.items.filter(m => m.sectorId == x.id)
        return {
          ...x, measurables : sectorMeasurables
        };
      })
      .sort((a, b) => parseInt(a.value2) - parseInt(b.value2));
      setSectors(mappedData);
    }
  };

  var weekOfYear = require('dayjs/plugin/weekOfYear');
  dayjs.extend(weekOfYear);
  var currentWeek = dayjs().week();

  useEffect(() => {
  }, []);

  const handleSelectPreviousWeeks = e => {
    console.log(e.target.value);
    setPreviousWeeks(e.target.value);
  };

  const handleSelectDate = date => {
    console.log(date);
    setSelectedDate(date);
  };

  return (
    <>
      <PageHeader
        title="Balanced Scorecard KPI"
        description={`Week no.${currentWeek} (calendar year)`}
        className="mb-3"
      ></PageHeader>
      <Card>
        <Card.Body>
          <Row>
            <Col sm={2}>
              <FormLabel>Date:</FormLabel>
              <Form>
                <DatePicker
                  selected={selectedDate}
                  dateFormat="dd/MM/yyyy"
                  className="form-control model-data"
                  onChange={date => {
                    handleSelectDate(date);
                  }}
                ></DatePicker>
              </Form>
            </Col>
            <Col sm={2}>
              <FormLabel>No. of latest weeks:</FormLabel>
              <Form.Select onChange={e => handleSelectPreviousWeeks(e)}>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4" selected={true}>
                  4
                </option>
              </Form.Select>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {sectors.map((x,index) => {
        return (
          <>
            <br />
            <SectorCard  key={index} selectedDate={selectedDate} noOfWeek={previousWeeks} sector={x} title={x.name} sectorId={x.id} />
          </>
        );
      })}
    </>
  );
}
